import React, {useState} from 'react';
import { Layout, Select, Space, Input, Button, notification } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-input-2'
import axios from 'axios';
import messages from '../../config/messages';

import 'antd/dist/antd.css';
import 'react-phone-input-2/lib/style.css'

const { Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;

const AppSms = () => {
  const [langDisabled, langSetDisabled] = useState(false);
  const [campDisabled, campSetDisabled] = useState(false);
  const [tempDisabled, tempSetDisabled] = useState(false);
  const [buttonDisabled, buttonSetDisabled] = useState(true);
  const [smsType, smsSetType] = useState("");
  const [smsText, smsSetText] = useState("");
  const [smsTo, smsSetTo] = useState("");
  const [langOptions, langOptionsSet] = useState([]);
  const [lang, langSet] = useState("");
  const [camp, campSet] = useState("");
  const [temp, tempSet] = useState("");
  const [campOptions, campOptionsSet] = useState([]);
  const [tempOptions, tempOptionsSet] = useState([]);
  const [inputValue, inputValueSet] = useState("");
  const [toValue, toValueSet] = useState("");

  const openNotification = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
      placement: "topLeft"
    });
  };

  function SmsTypeChange(value) {
    smsSetType(value); 

    if(value == "Manuel") {
      buttonSetDisabled(true);
      inputValueSet("");
      smsSetText("");
    } else {
      langSetDisabled(true);

      var langs = [];
      for(let lang in messages) langs.push({ label: lang, value: lang });

      langOptionsSet(langs);
    }
  }

  function LangChange(value) {
    langSet(value);
    campSetDisabled(true);
  
    var camps = [];
    for(let camp in messages[value]) camps.push({ name: camp, value: camp });

    campOptionsSet(camps);

    if(camp && temp) {
      inputValueSet(messages[value][camp][temp]);
      smsSetText(messages[value][camp][temp]);
    }
  }

  function CampChange(value) {
    campSet(value);

    var temps = [];
    for(let temp in messages[lang][value]) temps.push({ name: temp, value: temp });

    tempOptionsSet(temps);
    tempSetDisabled(true);

    if(lang && temp) {
      inputValueSet(messages[lang][value][temp]);
      smsSetText(messages[lang][value][temp]);
    }
  }

  function TempChange(value) {
    tempSet(value);
    buttonSetDisabled(true);

    inputValueSet(messages[lang][camp][value]);
    smsSetText(messages[lang][camp][value]);
  }

  function ToValueChange(value) {
    toValueSet(value);
    smsSetTo(value);
  }

  function TextValue(e) {
    inputValueSet(e.target.value);
    smsSetText(e.target.value);
  }

  const SendSms = async () => {
    try {
      if(!smsTo || !smsText) return openNotification("warning", "Send SMS Error!", "Missing parameter!");
      buttonSetDisabled(false);
      
      var sendSms = await axios({
        url: '/send-sms',
        method: "POST",
        data: {
          to: smsTo,
          text: smsText
        }
      });
      
      console.log(sendSms.data);

      buttonSetDisabled(true);
      if(sendSms.data.success) {
        return openNotification("success", "Send SMS Success!", "SMS sent successfully!");
      } else {
        return openNotification("warning", "Send SMS Error!", sendSms.data.message);
      }
    } catch (err) {
      buttonSetDisabled(true);
    }
  }

  return (
    <Content>
      <div className="child-header" align="center">
        <Space>
          <PhoneInput
            onlyCountries={['ro', 'md', 'ru']}
            country={'ro'}
            onChange={ToValueChange}
            value={toValue}
          />
          
          <Select
            style={{ width: 200 }}
            placeholder="SMS Type"
            onChange={SmsTypeChange}
          >
            <Option value="Automatic">Automatic</Option>
            <Option value="Manuel">Manuel</Option>
          </Select>

          <Select
            style={{ width: 200 }}
            placeholder="Language"
            disabled={!langDisabled}
            onChange={LangChange}
            options= {langOptions}
          >
          </Select>

          <Select
            style={{ width: 200 }}
            placeholder="Campaign"
            disabled={!campDisabled}
            onChange={CampChange}
            options= {campOptions}
          >
          </Select>
          
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="SMS Template"
            disabled={!tempDisabled}
            onChange={TempChange}
            options= {tempOptions}
          >
          </Select>
        </Space>

        <div className="child-body" align="center">
          <TextArea 
          rows={19}
          showCount 
          maxLength={1000}
          onChange={TextValue}
          value={inputValue}
          size="large"     
          />
        </div>

        <div className="child-body" align="center">
          <Button 
          disabled={!buttonDisabled} 
          type="primary" 
          shape="round" 
          icon={<SendOutlined />} 
          size="large"
          onClick={SendSms}
          >
            Send
          </Button>
        </div>
      </div>
    </Content> 
  );
};

export default AppSms;