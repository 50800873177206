import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Drawer, Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

import axios from 'axios';
import { serverUrl } from '../../config';

const AppHeader = (props) => {
  const [visible, setVisible] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['session']);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const logout = async () => {
    await axios({
      url: serverUrl,
      method: "GET",
      params: {
        function: "logout",
        session: cookies.session
      }
    });
    removeCookie("session");
    removeCookie("email");
    props.setIsAuth(false);
  };

  return (
    <div className="container-fluid">
      <div className="header">
        <div className="logo">
          <img src="pmi-logo.png"></img>
        </div>
        {
          props.isAuth ? 
            (
              <div align="right">
                  <Button  type="danger" icon={<LogoutOutlined />}  onClick={()=> logout()}>
                        Logout
                  </Button> 
              </div>  
            ) : 
            (
              <div></div>
            )
        } 
      </div>
    </div>
  );
}

export default AppHeader;