import AppSms from '../components/home/sms';

function AppHome() {
    return (
        <div className="main block child-tab-bar">
            <AppSms />
        </div>
    );
}

export default AppHome;