module.exports = {
    "Russian Kirillitsa": {
      "Generic": {
        "Warranty": "Здравствуйте! Узнайте больше о коммерческой и расширенной гарантии по ссылке: bit.ly/AW_RU С заботой, команда IQOS",
        "Devie Diagnostics": "Здравствуйте! Пошаговая диагностика устройства IQOS доступна в один клик: http://bit.ly/viberiqosmd С заботой, команда IQOS",
        "Broken Blade": "Здравствуйте! Вместе мы преодолеем эту ситуации, мы подготовили несколько решений: bit.ly/Diagnostic_3duo_RU С заботой, команда IQOS",
        "Smart Change": "Здравствуйте! Вы меняете, мы перерабатываем. Узнайте как, здесь: bit.ly/Smart-Change-ru",
        "Q-Metter GS": "Здравствуйте! Для участия в программе Q-Metter, пожалуйста, заполните эту форму: https://hub.iqos.md/survey/godson?lang=ru С заботой, команда IQOS",
        "Lending": "Здравствуйте! Запросите услугу аренды устройства IQOS в один клик bit.ly/leaseRU С заботой, команда IQOS",
        "Care Plus": "Здравствуйте! Узнайте больше о расширенной гарантии Care Plus здесь: bit.ly/AW_RU bit.ly/CarePlusRU С заботой, команда IQOS",
        "Product Catalogue": "Здраствуйте! Вы можете ознакомиться с доступными продуктами по ссылке: bit.ly/catalogRU С заботой, команда IQOS",
        "Buy IQOS Online": "Здраствуйте! Список наших партнеров, у которых вы можете купить IQOS онлайн: https://darwin.md/iqos-products https://iqos.buzz/Enter https://iqos.buzz/Foodhouse https://iqos.buzz/Straus-MD С заботой, команда IQOS",
        "Shop Locations Device Viber Map": "Здраствуйте! Список магазинов, где можете купить IQOS, здесь: http://bit.ly/viberiqosmd С заботой, команда IQOS",
        "Buy HEETS Online": "Здравствуйте! Список наших партнеров, у которых Вы можете купить HEETS онлайн здесь: https://iqos.buzz/Foodhouse https://iqos.buzz/Straus-MD С заботой, команда IQOS",
        "Shop Locations HEETS Viber map": "Здравствуйте! Список магазинов, где можете купить HEETS, здесь: http://bit.ly/viberiqosmd С заботой, команда IQOS",
        "International Assistance": "Здравствуйте! Перед следующей поездкой, узнайте больше о Международной Поддержке: bit.ly/InterAssitRU С заботой, команда IQOS",
        "Facebook Page Link": "Здравствуйте! Ждем вас на странице Facebookk: https://iqos.buzz/facebook С заботой, команда IQOS",
        "Viber Bot Page link": "Здравствуйте! Больше информации о поддержке IQOS в Viber bot: http://bit.ly/viberiqosmd С заботой, команда IQOS",
        "Website Page link": "Здравствуйте! Всего один клик и вы на сайте IQOS: https://md.iqos.com/ru С заботой, команда IQOS",
        "QHTB Adresses": "Здраствуйте! Команда IQOS будет Вам рада в магазинах Q Heated Tobacco Shop, по этим адресам: https://md.iqos.com/ru/locator",
        "Q-Metter GF": "Здравствуйте! Для участия в программе Q-Metter, пожалуйста, заполните эту форму:  https://hub.iqos.md/survey/godfather?lang=ru С заботой, команда IQOS",
        "Not reached LM": "Здравствуйте! Мы звонили вам с номера +40374129333, по поводу заявки,которую вы оставляли на нашем сайте md.iqos.com. Скоро мы попытаемся еще раз дозвониться до вас, так же вы можете связаться с нами бесплатно по номеру 080002400 Команда IQOS",
        "Not reached Generic (device related)": "Здравствуйте! Мы звонили вам с номера +40374129333, чтобы ответить вам на все вопросы по поводу IQOS. Скоро мы попытаемся еще раз дозвониться до вас, так же вы можете связаться с нами бесплатно по номеру 080002400 Команда IQOS",
        "Not reached Generic (our initiative)": "Здравствуйте! Мы попытались дозвонится до вас с номера +40374129333 (Клиентская Служба IQOS). Скоро мы попытаемся еще раз дозвониться до вас, так же вы можете связаться с нами бесплатно по номеру 080002400 Команда IQOS",
        "Not reached Generic (answer to consumer request)": "Здравствуйте! Мы попытались дозвониться до вас с номера +40374129333, чтобы сообщить вам ответ на ваш запрос. Вы можете связаться с нами по номеру 080002400, звонок бесплатный. С уважением,команда IQOS !",
        "Not reached Generic (solution confirmed)": "Здравствуйте! Мы попытались дозвонится до вас с номера +40374129333, чтобы подтвердить ваш запрос. Для подробной информации звоните нам  по номеру 080002400 (звонок бесплатный) С Уважением, команда IQOS !",
        "Link/Unlink Consent Request P1": "Здравствуйте! Мы звонили вам с номера +40374129333, чтобы проинформировать вас что поступил запрос о перерегистрации вашего устройства IQOS на другово пользователя.",
        "Link/Unlink Consent Request P2": "Если нам не удасться дозвониться до вас в течение 2-х дней, устройство будет перерегистрировано автоматически. Для подробной информации звоните нам по номеру 080002400. Звонок бесплатный. С уважением, команда IQOS",
        "Link/Unlink Done": "Здравствуйте! Ваше устройство IQOS было успешно зарегистрировано  на другого пользователя IQOS. Для подробной информации звоните нам  по номеру 080002400. Звонок бесплатный. С уважением, команда IQOS",
        "Link/Unlink Done 2": "Здравствуйте! Устройство IQOS было успешно зарегистрировано  на вашу учетную запись. Для подробной информации, звоните нам по номеру 080002400.  Звонок бесплатный. С уважением, команда IQOS",
        "Q-Metter Not Reached (welcome call)": "Здравствуйте!Мы звонили вам с номера +40374129333,по поводу опроса которого вы заполнили. Мы перезвоним позже или вы можете нам позвонить бесплатно по номеру 080002400 С уважением, команда IQOS",
        "Loyalty Not Reached (welcome call)": "Здравствуйте!Мы звонили вам с номера +40374129333, по поводу опроса, которого вы заполнили. Мы перезвоним вам позже,так же вы  можете связаться с нами по номеру 080002400 (звонок бесплатный). С уважением, команда IQOS !",
        "Loyalty Survey Invitation": "Здравствуйте!Нам крайне важно узнать о Вашем опыте в использовании IQOS и мы будем очень признательны, если вы поучаствуйте в нашем опросе, который поможет нам улучшить уровень обслуживания. Пройдите по ссылке: https://bit.ly/loyalty-survey-ru"
      },
      "RGT": {
        "First steps with IQOS": "Здравствуйте! Инструкции для правильного использования IQOS доступны здесь: bit.ly/UsageTipsRU",
        "Q-Metter GF": "Здравствуйте! Для участия в программе Q-Metter, пожалуйста, заполните эту форму:  https://hub.iqos.md/survey/godfather?lang=ru С заботой, команда IQOS",
        "Contact us possibilities": "Здравствуйте! Вы можете обращатся к нам в случаи вопросов по бесплатному номеру 080002400 или на странице Facebook: https://iqos.buzz/facebook",
        "Viber Bot Page link" : "Здравствуйте! Больше информации о поддержке IQOS в Viber bot: http://bit.ly/viberiqosmd С заботой, команда IQOS   ",
        "Not reached (welcome call)": "Здравствуйте!Недавно вы приобрели устройство IQOS и мы позвонили вам с номера +40374129333, чтоб ответить на ваши вопросы. Скоро мы попытаемся еще раз дозвониться до вас, так же вы можете связаться с нами бесплатно по номеру 080002400 Команда IQOS"
      },
      "Qoach": {
        "Q-Metter GF": "Здравствуйте! Для участия в программе Q-Metter, пожалуйста, заполните эту форму:  https://hub.iqos.md/survey/godfather?lang=ru С заботой, команда IQOS",
        "Contact us possibilities": "Здравствуйте! Вы можете обращатся к нам в случаи вопросов по бесплатному номеру 080002400 или на странице Facebook: https://iqos.buzz/facebook",
        "Viber Bot Page link": "Здравствуйте! Больше информации о поддержке IQOS в Viber bot: http://bit.ly/viberiqosmd С заботой, команда IQOS",
        "Recommendations for use": "Здравствуйте! Инструкции для правильного использования IQOS доступны здесь: bit.ly/UsageTipsRU",
        "Not reached (welcome call)": "Здравствуйте!Недавно вы приобрели устройство IQOS и мы позвонили вам с номера +40374129333, чтоб ответить на ваши вопросы. Скоро мы попытаемся еще раз дозвониться до вас, так же вы можете связаться с нами бесплатно по номеру 080002400 Команда IQOS"
      },
      "Leads Managements": {
        "Q-Metter GS": "Здравствуйте! Для участия в программе Q-Metter, пожалуйста, заполните эту форму: https://hub.iqos.md/survey/godson?lang=ru С заботой, команда IQOS",
        "Q-Metter GF": "Здравствуйте! Для участия в программе Q-Metter, пожалуйста, заполните эту форму:  https://hub.iqos.md/survey/godfather?lang=ru С заботой, команда IQOS",
        "Not reached LM": "Здравствуйте! Мы звонили вам с номера +40374129333, по поводу заявки,которую вы оставляли на нашем сайте md.iqos.com. Скоро мы попытаемся еще раз дозвониться до вас, так же вы можете связаться с нами бесплатно по номеру 080002400 Команда IQOS",
        "Smart Change": "Здравствуйте! Вы меняете, мы перерабатываем. Узнайте как, здесь: bit.ly/Smart-Change-ru С заботой о Вас и экологии, команда IQOS",
        "Lending": "Здравствуйте! Запросите услугу аренды устройства IQOS в один клик bit.ly/leaseRU С заботой, команда IQOS",
        "Product Catalogue": "Здраствуйте! Вы можете ознакомиться с доступными продуктами по ссылке: bit.ly/catalogRU С заботой, команда IQOS",
        "Buy IQOS Online": "Здраствуйте! Список наших партнеров, у которых вы можете купить IQOS онлайн: https://darwin.md/iqos-products https://iqos.buzz/Enter https://iqos.buzz/Foodhouse https://iqos.buzz/Straus-MD С заботой, команда IQOS",
        "Shop Locations Device Viber Map": "Здраствуйте! Список магазинов, где можете купить IQOS, здесь: http://bit.ly/viberiqosmd С заботой, команда IQOS",
        "Buy HEETS Online": "Здравствуйте! Список наших партнеров, у которых Вы можете купить HEETS онлайн: https://iqos.buzz/Foodhouse https://iqos.buzz/Straus-MD С заботой, команда IQOS",
        "Shop Locations HEETS Viber map": "Здравствуйте! Список магазинов, где можете купить HEETS: http://bit.ly/viberiqosmd С заботой, команда IQOS",
        "QHTB Adresses": "Здраствуйте! Команда IQOS будет Вам рада в магазинах Q Heated Tobacco Shop, по этим адресам: https://md.iqos.com/ru/locator"
      },
      "Lending": {
        "Not reached Generic": "Здравствуйте! Мы звонили вам с номера +40374129333, чтобы ответить вам на все вопросы по поводу IQOS. Скоро мы попытаемся еше раз до вас дозвонится или вы можете нам звонить на 080002400 (звонок бесплатный) С заботой, команда IQOS",
        "Lending expiration period reminder": "Здравствуйте, срок аренды устройства подходит к концу. Готовы узнать больше об услугах и опциях после аренды? Вы можете сделать это здесь: bit.ly/OpToBuy_RU",
        "Lending expired return follow up": "Здравствуйте, срок аренды устройства подошел к концу. Напоминаем Вам, что необходимо вернуть устройство в точку продажи, где оно было выдано. Для подробностей позвоните нам по номеру 080002400 (звонок бесплатный)",
        "MGL": "Здравствуйте, вы можете порекомендовать друга для услуги аренды устройства здесь: https://hub.iqos.md/survey-1?lang=ru",
        "Not reached (welcome call)": "Здравствуйте!Недавно вы взяли в аренду устройство IQOS и мы позвонили вам с номера +40374129333, чтоб ответить на ваши вопросы.Мы попытаемся еще раз дозвониться до вас, так же вы можете связаться с нами бесплатно по номеру 080002400 Команда IQOS"
      }
    },
    "Russian": {
      "Generic": {
        "Warranty": "Zdravstvujte! Uznajte bol'she o kommercheskoj i rasshirennoj garantii po ssylke: bit.ly/AW_RU S zabotoj, komanda IQOS",
        "Devie Diagnostics": "Zdravstvujte! Poshagovaja diagnostika ustrojstva IQOS dostupna v odin klik: http://bit.ly/viberiqosmd S zabotoj, komanda IQOS",
        "Broken Blade": "Zdravstvujte! Vmeste my preodoleem jetu situacii, my podgotovili neskol'ko reshenij: bit.ly/Diagnostic_3duo_RU S zabotoj, komanda IQOS",
        "Smart Change": "Zdravstvujte! Vy menjaete, my pererabatyvaem. Uznajte kak, zdes': bit.ly/Smart-Change-ru",
        "Q-Metter GS": "Zdravstvujte! Dlja uchastija v programme Q-Metter, pozhalujsta, zapolnite jetu formu: https://hub.iqos.md/survey/godson?lang=ru S zabotoj, komanda IQOS",
        "Lending": "Zdravstvujte! Zaprosite uslugu arendy ustrojstva IQOS v odin klik bit.ly/leaseRU S zabotoj, komanda IQOS",
        "Care Plus": "Zdravstvujte! Uznajte bol'she o rasshirennoj garantii Care Plus zdes': bit.ly/AW_RU bit.ly/CarePlusRU S zabotoj, komanda IQOS",
        "Product Catalogue": "Zdrastvujte! Vy mozhete oznakomit'sja s dostupnymi produktami po ssylke: bit.ly/catalogRU S zabotoj, komanda IQOS",
        "Buy IQOS Online": "Zdrastvujte! Vy mozhete kupit' IQOS onlajn: https://darwin.md/iqos-products https://iqos.buzz/Enter https://iqos.buzz/Foodhouse https://iqos.buzz/Straus-MD",
        "Shop Locations Device Viber Map": "Zdrastvujte! Spisok magazinov, gde mozhete kupit' IQOS, zdes': http://bit.ly/viberiqosmd S zabotoj, komanda IQOS",
        "Buy HEETS Online": "Zdravstvujte!Spisok nashih partnerov, u kotoryh Vy mozhete kupit' HEETS onlajn: https://iqos.buzz/Foodhouse https://iqos.buzz/Straus-MD S zabotoj, komanda IQOS",
        "Shop Locations HEETS Viber map": "Zdravstvujte! Spisok magazinov, gde mozhete kupit' HEETS: http://bit.ly/viberiqosmd S zabotoj, komanda IQOS",
        "International Assistance": "Zdravstvujte! Pered sledujushhej poezdkoj, uznajte bol'she o Mezhdunarodnoj Podderzhke:  bit.ly/InterAssitRU S zabotoj, komanda IQOS",
        "Facebook Page Link": "Zdravstvujte! My budem rady Vam na IQOS Moldova v Facebook:  https://iqos.buzz/facebook S zabotoj, komanda IQOS",
        "Viber Bot Page link": "Zdravstvujte! Bol'she informacii o podderzhke IQOS v Viber bot: http://bit.ly/viberiqosmd S zabotoj, komanda IQOS",
        "Website Page link": "Zdravstvujte! Vsego odin klik i vy na sajte IQOS: https://md.iqos.com/ru S zabotoj, komanda IQOS",
        "QHTB Adresses": "Zdrastvujte! Komanda IQOS budet Vam rada v magazinah Q Heated Tobacco Shop, po jetim adresam: https://md.iqos.com/ru/locator",
        "Q-Metter GF": "Zdravstvujte! Dlja uchastija v programme Q-Metter, pozhalujsta, zapolnite jetu formu:  https://hub.iqos.md/survey/godfather?lang=ru S zabotoj, komanda IQOS",
        "Not reached LM": "Zdravstvujte!My pozvonili vam s nomera +40374129333,po povodu zakaza na iqos.com My perezvonim pozhe ili vy mozhete nam pozvonit' besplatno po nomeru 080002400",
        "Not reached Generic (device related)": "Zdravstvujte!My nedavno zvonili vam s nomera +40374129333,po povodu ustrojstva IQOS.My perezvonim pozhe ili vy mozhete nam zvonit' besplatno po nomeru 080002400",
        "Not reached Generic (our initiative)": "Zdravstvujte!My pozvonili vam s nomera +40374129333 (Klientskaya Sluzhba IQOS).My perezvonim pozhe ili vy mozhete nam pozvonit' besplatno po nomeru 080002400",
        "Not reached Generic (answer to consumer request)": "Zdravstvujte!My pozvonili vam s nomera +40374129333,chtoby soobshchit' vam otvet na vash zapros.Uznajte podrobnosti po besplatnomu nomeru 080002400 Komanda IQOS",
        "Not reached Generic (solution confirmed)": "Zdravstvujte!My pozvonili vam s nomera +40374129333,chtoby podtverdit' odobreniya vashego zaprosa. Podrobnosti po 080002400 (zvonok besplatnyj) Komanda IQOS",
        "Link/Unlink Consent Request P1": "Zdravstvujte! My zvonili vam s nomera +40374129333, chtoby proinformirovat' vas o zaprose pereregistracii vashego ustrojstva IQOS na drugovo pol'zovatelya.",
        "Link/Unlink Consent Request P2": "Esli nam ne udast'sya dozvonit'sya do vas v techenie 2-h dnej, ustrojstvo budet pereregistrirovano avtomaticheski. Komanda IQOS",
        "Link/Unlink Done": "Zdravstvujte! Vashe ustrojstvo IQOS bylo uspeshno zaregistrirovano na drugogo pol'zovatelya IQOS. Dlya podrobnostei, pozvonite po besplantomy nomeru 080002400",
        "Link/Unlink Done 2": "Zdravstvujte!Ustrojstvo IQOS bylo uspeshno zaregistrirovano na vashu uchetnuyu zapis'. Dlya podrobnostei, pozvonite po besplantomy nomeru 080002400",
        "Q-Metter Not Reached (welcome call)": "Zdravstvujte!My zvonili vam s nomera +40374129333,po povodu oprosa kotorogo vy zapolnili. Podrobnosti po besplantomy nomeru 080002400 Komanda IQOS",
        "Loyalty Not Reached (welcome call)": "Zdravstvujte!My zvonili vam s nomera +40374129333,po povodu oprosa kotorogo vy zapolnili. Podrobnosti po besplantomy nomeru 080002400 Komanda IQOS",
        "Loyalty Survey Invitation": "Zdravstvujte!Nam krajne vazhno znat' kak mozhno bol'she o Vashem opyte i my budem ochen' priznatel'ny esli vy pouchastvujte v nashem oprose kotoryj pomozhet nam ulutshchit' uroven' predostavlenyh uslug. Nazhmite zdes': https://bit.ly/loyalty-survey-ru"
      },
      "RGT": {
        "First steps with IQOS": "Zdravstvujte! Instrukcii dlya pravil'nogo ispol'zovaniya IQOS dostupny zdes': bit.ly/UsageTipsRU",
        "Q-Metter GF": "Zdravstvujte! Dlja uchastija v programme Q-Metter, pozhalujsta, zapolnite jetu formu:  https://hub.iqos.md/survey/godfather?lang=ru S zabotoj, komanda IQOS",
        "Contact us possibilities": "Zdravstvujte!Vy mozhete obrashchatsya k nam v sluchai voprosov po besplatnomu nomeru 080002400 ili na stranice Facebook: https://iqos.buzz/facebook Komanda IQOS",
        "Viber Bot Page link" : "Zdravstvujte! Bol'she informacii o podderzhke IQOS v Viber bot: http://bit.ly/viberiqosmd S zabotoj, komanda IQOS",
        "Not reached (welcome call)": "Zdravstvujte!Nedavno vy priobreli ustrojstvo IQOS i my pozvonili vam s nomera +40374129333, chtob otvetit' na vashi voprosy. Poprobuem pozvonit' vam pozzhe"
      },
      "Qoach": {
        "Q-Metter GF": "Zdravstvujte! Dlya uchastiya v programme Q-Metter, pozhalujsta, zapolnite etu formu:  https://hub.iqos.md/survey/godfather?lang=ru S zabotoj, komanda IQOS",
        "Contact us possibilities": "Zdravstvujte!Vy mozhete obrashchatsya k nam v sluchai voprosov po besplatnomu nomeru 080002400 ili na stranice Facebook: https://iqos.buzz/facebook Komanda IQOS",
        "Viber Bot Page link": "Zdravstvujte! Bol'she informacii o podderzhke IQOS v Viber bot: http://bit.ly/viberiqosmd S zabotoj, komanda IQOS",
        "Recommendations for use": "Zdravstvujte! Instrukcii dlya pravil'nogo ispol'zovaniya IQOS dostupny zdes': bit.ly/UsageTipsRU Komanda IQOS",
        "Not reached (welcome call)": "Zdravstvujte!Nedavno vy priobreli ustrojstvo IQOS i my pozvonili vam s nomera +40374129333, chtob otvetit' na vashi voprosy. Poprobuem pozvonit' vam pozzhe"
      },
      "Leads Managements": {
        "Q-Metter GS": "Zdravstvujte! Dlja uchastija v programme Q-Metter, pozhalujsta, zapolnite jetu formu: https://hub.iqos.md/survey/godson?lang=ru S zabotoj, komanda IQOS",
        "Q-Metter GF": "Zdravstvujte! Dlja uchastija v programme Q-Metter, pozhalujsta, zapolnite jetu formu:https://hub.iqos.md/survey/godfather?lang=ru S zabotoj, komanda IQOS",
        "Not reached LM": "Zdravstvujte!My nedavno zvonili vam s nomera +40374129333,po povodu zajavki IQOS.My perezvonim pozzhe ili vy mozhete nam pozvonit' besplatno po nomeru 080002400",
        "Smart Change": "Zdravstvujte! Vy menjaete, my pererabatyvaem. Uznajte kak, zdes': bit.ly/Smart-Change-ru S zabotoj o Vas i jekologii, komanda IQOS",
        "Lending": "Zdravstvujte! Zaprosite uslugu arendy ustrojstva IQOS v odin klik bit.ly/leaseRU S zabotoj, komanda IQOS",
        "Product Catalogue": "Zdrastvujte! Vy mozhete oznakomit'sja s dostupnymi produktami po ssylke: bit.ly/catalogRU S zabotoj, komanda IQOS",
        "Buy IQOS Online": "Zdrastvujte! Vy mozhete kupit' IQOS onlajn zdes' https://darwin.md/iqos-products https://iqos.buzz/Enter https://iqos.buzz/Foodhouse https://iqos.buzz/Straus-MD",
        "Shop Locations Device Viber Map": "Zdrastvujte! Spisok magazinov, gde mozhete kupit' IQOS, zdes': http://bit.ly/viberiqosmd S zabotoj, komanda IQOS",
        "Buy HEETS Online": "Zdravstvujte!Spisok nashih partnerov, u kotoryh Vy mozhete kupit' HEETS onlajn: https://iqos.buzz/Foodhouse https://iqos.buzz/Straus-MD S zabotoj, komanda IQOS",
        "Shop Locations HEETS Viber map": "Zdravstvujte! Spisok magazinov, gde mozhete kupit' HEETS: http://bit.ly/viberiqosmd S zabotoj, komanda IQOS",
        "QHTB Adresses": "Zdrastvujte! Komanda IQOS budet Vam rada v magazinah Q Heated Tobacco Shop, po jetim adresam: https://md.iqos.com/ru/locator"
      },
      "Lending": {
        "Not reached Generic": "Zdravstvujte!My nedavno zvonili vam s nomera +40374129333,po povodu ustrojstva IQOS.My perezvonim pozhe ili vy mozhete nam zvonit' besplatno po nomeru 080002400",
        "Lending expiration period reminder": "Zdravstvujte, srok arendy ustrojstva podhodit k koncu. Gotovy uznat' bol'she ob uslugah i opciyah posle arendy? Vy mozhete sdelat' eto zdes': bit.ly/OpToBuy_RU",
        "Lending expired return follow up": "Zdravstvujte,srok arendy IQOS podoshel k koncu.Mozhete vernut' ustrojstvo v tochku prodazhi,gde ono bylo vydano.Podrobnostj zdes':080002400 (zvonok besplatnyj)",
        "MGL": "Zdravstvujte, vy mozhete porekomendovat' druga dlya uslugi arendy ustrojstva zdes': https://hub.iqos.md/survey-1?lang=ru Komanda IQOS",
        "Not reached (welcome call)": "Zdravstvujte!Nedavno vy vzyali v arendu ustrojstvo IQOS i my pozvonili vam s nomera +40374129333, chtob otvetit' na vashi voprosy.Poprobuem pozvonit' vam pozzhe"
      }
    },
    "Romanian": {
      "Generic": {
        "Warranty": "Buna! Aflati mai multe despre garantia comerciala si cea extinsa aici: bit.ly/AW_RO Cu grija, echipa IQOS",
        "Devie Diagnostics": "Buna! Diagnosticul pas cu pas al dispozitivului IQOS e la un click distanta: http://bit.ly/viberiqosmd Cu grija, echipa IQOS",
        "Broken Blade": "Buna! Suntem alaturi pentru a rezolva aceasta situatie impreuna. Aici gasiti cateva solutii: bit.ly/Diagnostic_3duo_RO Cu grija, echipa IQOS",
        "Smart Change": "Buna! Voi schimbati, noi reciclam. Aflati cum, aici: bit.ly/Smart-Change-ro Cu grija pentru dvs si mediu, echipa IQOS",
        "Q-Metter GS": "Buna! Pentru a participa la programul Q-Metter, completati acest formular: https://hub.iqos.md/survey/godson?lang=ro",
        "Lending": "Buna! Solicitati serviciul de  inchiriere a dispozitivului IQOS intr-un click: bit.ly/leaseRO Cu drag, echipa IQOS",
        "Care Plus": "Buna! Aflati mai multe despre garantia extinsa Care Plus, aici: bit.ly/AW_RO bit.ly/CarePlusRO Cu grija, echipa IQOS",
        "Product Catalogue": "Buna! Vedeti portofoliul produselor disponibile aici: bit.ly/catalog_RO Cu drag, echipa IQOS",
        "Buy IQOS Online": "Buna! IQOS poate fi procurat online: https://darwin.md/iqos-products https://iqos.buzz/Enter https://iqos.buzz/Foodhouse https://iqos.buzz/Straus-MD Echipa IQOS",
        "Shop Locations Device Viber Map": "Buna! Gasiti aici lista magazinelor de unde puteti procura IQOS: http://bit.ly/viberiqosmd Cu grija, echipa IQOS",
        "Buy HEETS Online": "Buna! Gasiti lista partenerilor nostri de unde puteți procura HEETS online aici: https://iqos.buzz/Foodhouse https://iqos.buzz/Straus-MD Cu drag, echipa IQOS",
        "Shop Locations HEETS Viber map": "Buna! Gasiti lista magazinelor de unde puteti procura HEETS aici: http://bit.ly/viberiqosmd Cu drag, echipa IQOS",
        "International Assistance": "Buna! Inainte de urmatoarea calatorie, informati-va despre Asistenta Internatională: bit.ly/InternAssistRO Cu grija, echipa IQOS",
        "Facebook Page Link": "Buna! Va asteptam cu drag pe pagina de Facebook: https://iqos.buzz/facebook Cu grija, echipa IQOS",
        "Viber Bot Page link": "Buna! Gasiti informatii si asistenta in Viber Bot: http://bit.ly/viberiqosmd Cu grija, echipa IQOS",
        "Website Page link": "Buna! Accesati site-ul nostru aici: https://md.iqos.com/ro Cu drag, echipa IQOS",
        "QHTB Adresses": "Buna! Echipa IQOS va asteapta cu drag in magazinele Q Heated Tobacco Shop, la aceste adrese: https://md.iqos.com/ro/locator",
        "Q-Metter GF": "Buna! Pentru a participa la programul Q-Metter, va rugam sa completati acest formular: https://hub.iqos.md/survey/godfather Cu drag, echipa IQOS",
        "Not reached LM": "Buna,v-am apelat recent de pe numarul +40374129333 in legatura cu comanda Dvs. IQOS.Incercam mai tarziu sau ne puteti contacta gratuit la 080002400. Echipa IQOS",
        "Not reached Generic (device related)": "Buna,v-am apelat de pe numarul +40374129333 in legatura cu dispozitivul Dvs. IQOS.Incercam mai tarziu sau ne puteti contacta gratuit la 080002400. Echipa IQOS",
        "Not reached Generic (our initiative)": "Buna,am incercat sa va contactam de pe numarul +40374129333 (Serviciul Clienti IQOS) Incercam mai tarziu sau ne puteti contacta la numarul gratuit 080002400",
        "Not reached Generic (answer to consumer request)": "Buna,am incercat sa va contactam de pe numarul +40374129333,pentru a va oferi raspuns la cererea Dvs.Ne puteti contacta gratuit la numarul 080002400 Echipa IQOS",
        "Not reached Generic (solution confirmed)": "Buna,am incercat sa va contactam de pe numarul +40374129333 pentru a confirma cererea Dvs. depusa la Serviciul Clienti IQOS. Detalii la: 080002400(apel gratuit)",
        "Link/Unlink Consent Request P1": "Buna, v-am apelat de pe numarul +40374129333, pentru a va informa despre cererea parvenita de a inregistra dispozitivul Dvs. IQOS pe contul altui utilizator.",
        "Link/Unlink Consent Request P2": "In caz ca nu ne reuseste sa luam legatura cu Dvs. in decursul a 2 zile, dispozitivul Dvs. va fi reinregistrat automat Cu drag, echipa IQOS",
        "Link/Unlink Done": "Buna, dispozitivul Dvs. IQOS a fost transferat cu succes pe contul altui utilizator. Pentru mai multe informatii va rugam sa ne apelati gratuit la 080002400",
        "Link/Unlink Done 2": "Buna, dispozitivul IQOS a fost transferat cu succes pe contul Dvs. de utilizator. Pentru mai multe informatii va rugam sa ne apelati gratuit la 080002400",
        "Q-Metter Not Reached (welcome call)": "Buna,v-am apelat de pe numarul +40374129333, in legatura cu chestionarul completat recent.Incercam mai tarziu sau ne puteti apela la numarul gratuit 080002400",
        "Loyalty Not Reached (welcome call)": "Buna,v-am apelat de pe numarul +40374129333, in legatura cu chestionarul completat recent.Incercam mai tarziu sau ne puteti apela la numarul gratuit 080002400",
        "Loyalty Survey Invitation": "Buna,suntem curiosi sa aflam cat mai multe despre experienta Dvs. Am aprecia mult daca ati participa la chestionarul de pe mai jos,care ne va ajuta sa dezvoltam servicii la nivelul asteptarilor Dvs. Tastati aici: https://bit.ly/loyalty-survey-ro"
      },
      "RGT": {
        "First steps with IQOS": "Buna! Primii pasi cu IQOS și ghidul utilizatorului sunt disponibile aici : bit.ly/UsageTipsRO Echipa IQOS",
        "Q-Metter GF": "Buna! Pentru a participa la programul Q-Metter, va rugam sa completati acest formular: https://hub.iqos.md/survey/godfatherCu drag, echipa IQOS",
        "Contact us possibilities": "Buna,suntem aici pentru a va oferi asistenta, ne puteti contacta gratuit la numarul 080002400 sau pe pagina de Facebook: https://iqos.buzz/facebook Echipa IQOS",
        "Viber Bot Page link" : "Buna! Gasiti informatii si asistenta in Viber Bot: http://bit.ly/viberiqosmd Cu grija, echipa IQOS",
        "Not reached (welcome call)": "Buna, recent ati procurat un dispozitiv IQOS si v-am contactat de pe numarul +40374129333, pentru a va oferi asistenta. Ne puteti contacta gratuit la 080002400"
      },
      "Qoach": {
        "Q-Metter GF": "Buna! Pentru a participa la programul Q-Metter, va rugam sa completati acest formular: https://hub.iqos.md/survey/godfather Cu drag, echipa IQOS",
        "Contact us possibilities": "Buna,suntem aici pentru a va oferi asistenta, ne puteti contacta gratuit la numarul 080002400 sau pe pagina de Facebook: https://iqos.buzz/facebook Echipa IQOS",
        "Viber Bot Page link": "Buna! Gasiti informatii si asistenta in Viber Bot: http://bit.ly/viberiqosmd Cu grija, echipa IQOS",
        "Recommendations for use": "Buna! Accesati linkul de mai jos pentru a vedea recomandarile si ghidul de utilizare IQOS: bit.ly/UsageTipsRO Echipa IQOS",
        "Not reached (welcome call)": "Buna, recent ati procurat un dispozitiv IQOS si v-am contactat de pe numarul +40374129333, pentru a va oferi asistenta. Ne puteti contacta gratuit la 080002400"
      },
      "Leads Managements": {
        "Q-Metter GS": "Buna! Pentru a participa la programul Q-Metter, va rugam sa completati acest formular: https://hub.iqos.md/survey/godson?lang=ro Cu drag, echipa IQOS",
        "Q-Metter GF": "Buna! Pentru a participa la programul Q-Metter, va rugam sa completati acest formular: https://hub.iqos.md/survey/godfather Cu drag, echipa IQOS",
        "Not reached LM": "Buna, v-am apelat recent de pe numarul +40374129333 in legatura cu comanda Dvs. IQOS. Incercam mai tarziu sau ne puteti contacta gratuit la 080002400.",
        "Smart Change": "Buna! Voi schimbati, noi reciclam. Aflati cum, aici: bit.ly/Smart-Change-ro Cu grija pentru dvs si mediu, echipa IQOS",
        "Lending": "Buna! Solicitati serviciul de  inchiriere a dispozitivului IQOS intr-un click: bit.ly/leaseRO Cu drag, echipa IQOS",
        "Product Catalogue": "Buna! Vedeti portofoliul produselor disponibile aici: bit.ly/catalog_RO Cu drag, echipa IQOS",
        "Buy IQOS Online": "Buna! IQOS poate fi procurat online aici: https://darwin.md/iqos-products https://iqos.buzz/Enter https://iqos.buzz/Foodhouse https://iqos.buzz/Straus-MD",
        "Shop Locations Device Viber Map": "Buna! Gasiti aici lista magazinelor de unde puteti procura IQOS: http://bit.ly/viberiqosmd Cu grija, echipa IQOS",
        "Buy HEETS Online": "Buna! Gasiti lista partenerilor nostri de unde puteți procura HEETS online aici: https://iqos.buzz/Foodhouse https://iqos.buzz/Straus-MD Cu drag, echipa IQOS",
        "Shop Locations HEETS Viber map": "Buna! Gasiti lista magazinelor de unde puteti procura HEETS aici: http://bit.ly/viberiqosmd Cu drag, echipa IQOS",
        "QHTB Adresses": "Buna! Echipa IQOS va asteapta cu drag in magazinele Q Heated Tobacco Shop, la aceste adrese: https://md.iqos.com/ro/locator"
      },
      "Lending": {
        "Not reached Generic": "Buna,v-am apelat de pe numarul +40374129333 in legatura cu dispozitivul Dvs. IQOS. Incercam mai tarziu sau ne puteti contacta gratuit la 080002400. Echipa IQOS",
        "Lending expiration period reminder": "Buna,perioada de inchiriere a Dispozitivului se apropie de sfarsit. Doriti sa aflati care sunt optiunile disponibile dupa inchiriere? Tastati: bit.ly/OpToBuy_RO",
        "Lending expired return follow up": "Buna,perioada de inchiriere a expirat.Dispozitivul poate fi returnat in punctul de vanzare din care l-ati preluat. Detalii la: 080002400 (apel gratuit)",
        "MGL": "Buna, puteti recomanda un prieten pentru serviciul de inchiriere a dispozitivului IQOS aici: https://hub.iqos.md/survey-1?lang=ro Echipa IQOS",
        "Not reached (welcome call)": "Buna, recent ati inchiriat un dispozitiv IQOS si v-am contactat de pe numarul +40374129333, pentru a va oferi asistenta. Ne puteti contacta gratuit la 080002400"
      }
    }
};